* {
  font-family: "JetBrains Mono", monospace;
}

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.cdnfonts.com/css/rhodeport');

.inputSection select.Receive:focus {
  outline: none;
}

.inputSection option {
  background-color: rgba(255, 255, 255, 0);
  color: #333;
}

.pText {
  text-align: center;
  margin: auto;
  display: block;
}

.viewTxn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

#loadingT {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: rgb(0, 27, 53);
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */

.loader:after {
  animation-duration: 4s;
}

@keyframes l24 {
  100% {
    transform: rotate(1turn)
  }
}

.formMain2 {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.103);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px)
}

.center {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.purchase-form button:active {
  transform: scale(0.95);
  /* Slightly reduce the size to give a pressed effect */
}

.logoDivMob img:hover {
  cursor: pointer;
}

@keyframes l20 {

  90%,
  100% {
    background-size: 100% 100%
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  50% {
    box-shadow: 0 0 20px rgba(0, 123, 255, 1);
  }

  100% {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}

@keyframes textFade {
  0% {
    color: #9fd1ff;
    /* Full opacity */
  }

  50% {
    color: #022b52;
    /* Half opacity */
  }

  100% {
    color: #9fd1ff;
    /* Full opacity */
  }
}

.cont2 {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.6632140084598841) 0%, rgba(0, 0, 0, 0.6547870421677493) 50%, rgba(0, 6, 9, 1) 100%), url('./assets/0_0.jpeg') no-repeat center center fixed;
  /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: black;
  height: max-content;
  overflow: hidden;
  background-size: cover;
}

.tokenLink2:hover {
  color: #4d4d4d;
}

.headerHL {
  color: #afd7fd;
  font-weight: bold;
}

.buyImg:hover {
  cursor: pointer;
}

.storyCon--2 {
  width: 90%;
  color: white;
}

@keyframes neonGlow {
  0% {
    border-color: #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
  }

  50% {
    border-color: #e82598;
    box-shadow: 0 0 10px #62b9e7, 0 0 25px #62b9e7, 0 0 35px #62b9e7;
  }

  100% {
    border-color: #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
  }
}


.btnfos-0-3-3 .initialText {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.btnfos-0-3-3:hover .initialText {
  opacity: 0;
  visibility: hidden;
}

.btnfos-0-3-3 .hoverText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  width: 90%;
}

.btnfos-0-3-3:hover .hoverText {
  opacity: 1;
  visibility: visible;
}

.btnfos-0-3:active {
  background-color: rgb(15, 129, 223);
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.bannerDetails {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  width: 90%;
  border-color: #4e0966;
  box-shadow: 0 0 20px #af1ae0;
  border-radius: 50px;
}

@keyframes blink {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.logoDiv {
  cursor: pointer;
}

.page-loader {
  width: 100%;

  background: #242424;
  z-index: 5000000000000000;
  position: fixed;
}

.glitch-wrapperLoad {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.glitchLoad::before,
.glitchLoad::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitchLoad::before {
  left: 2px;
  text-shadow: -2px 0 #0084ff;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitchLoad::after {
  left: -2px;
  text-shadow: -2px 0 #0084ff;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

.page-loader .txt {
  color: #ffffff;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: black 3px 5px 2px;
  -webkit-text-stroke: 1.5px black;
  font-size: 50px;
}

.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.loading-area {
  display: grid;
  place-items: center;

}

.loader div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transform: scale(0);
  animation: animate 1.5s ease-in-out infinite;
  display: inline-block;
  margin: .5rem;
}

.loader div:nth-child(0) {
  animation-delay: 0s;
}

.loader div:nth-child(1) {
  animation-delay: 0.2s;
}

.loader div:nth-child(2) {
  animation-delay: 0.4s;
}

.loader div:nth-child(3) {
  animation-delay: 0.6s;
}

.loader div:nth-child(4) {
  animation-delay: 0.8s;
}

.loader div:nth-child(5) {
  animation-delay: 1s;
}

.loader div:nth-child(6) {
  animation-delay: 1.2s;
}

.loader div:nth-child(7) {
  animation-delay: 1.4s;
}

@keyframes animate {

  0%,
  100% {
    transform: scale(0.2);
    background-color: #d3e8ec;
  }

  40% {
    transform: scale(1);
    background-color: #8a6d57;
  }

  50% {
    transform: scale(1);
    background-color: #516479;
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    width: 0;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #02ace0;
}

.formMain-2-2 {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  background-color: rgba(255, 255, 255, 0);
  animation: popupAnimation 0.5s ease forwards;
  padding: 10px;
  padding-top: 50px;
  border-radius: 20px;
}

@media screen and (max-width: 499px) {
  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 90%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 10px;
    padding-top: 50px;
    border-radius: 20px;
  }

  .viewTxn {
    font-size: 16px;
    margin-top: 15px;
    color: rgb(0, 59, 0);
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .containerD {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .containerD p {
    font-size: 16px;
    color: #02ace0;
    margin-top: 15px;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 30px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);

  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }

  .container {
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
  }

  .title {
    font-size: 25px;
    margin-bottom: 1rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .progress {
    height: 20px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    color: #d1d1d1;
    flex-flow: column nowrap;
  }

  .stat {
    background-color: #0000006b;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 15px;
    padding-top: 20px;
  }

  .stat h2 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    width: 100%;
  }

  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 5px;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 20px;
    color: #90c9ff;
    background-color: #3f3f3f6c;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 30%;
    margin: auto;
    display: block;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }  

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  } 

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 5px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 15px;
    border-radius: 100px;
    border: none;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 3px solid #073c6e;
    transition: transform .5s;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }


  .btnPresale:hover {
    transform: scale(1.1);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 20px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 18px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .title_text {
    font-size: 43px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 0.2px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: -150px;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .boxWrap2Page {
    background-color: #010408;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .buyImg {
    width: 80px;
    margin-left: 4%;
    margin-top: 20px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 22px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 4%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 95%;
    display: flex;
    flex-flow: row wrap;
  }

  .partnerDiv img {
    width: 100px;
    height: 37px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }


  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
    margin-bottom: 50px;
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 16px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .square {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }


  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    display: none;
  }

  .tokenLink {

    font-size: 10px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  .glitchLoad {
    font-size: 25px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #02ace0;
    padding: 10px;
    width: 100%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;
    flex-flow: column nowrap;

  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
    background-color: #00000077;
    padding: 5px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 50px;
    padding-top: 20px;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 90%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 6%;

  }

  .videoMain {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 4%;
    margin-left: 29.3%;
    cursor: pointer;

  }

  video {
    z-index: 1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 17px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 14px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMain {
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .cContentMainMy {
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .relativeMain {
    width: 100%;
    height: 100%;
    margin-top: 8%;
    display: none;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    background-color: #000000c2;
    display: flex;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: auto;

  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 25px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }


  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    margin-left: auto;
    margin-right: auto;
    display: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;


  }

  .contMint {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }


  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 50%;
    margin-top: -5%;
  }

  .rocketsImg2 {
    width: 50%;
    margin-top: -5%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
    display: none;
  }

  .logoDiv img {
    width: 55%;
  }

  .logoDivMob {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
  }

  .logoDivMob img {
    width: 50%;
    margin-left: 20px;
  }

  .title {
    margin-top: 10vh;
    z-index: 1;
    justify-content: center;
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 18px;
    text-shadow: 0 0 10px #02ace0;
    ;
    line-height: 20px;
    margin-top: 10px;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 35px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 18px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #ff1c54;
  }

  .download {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: cover;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: cover;
    display: none;
  }


  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(180deg, rgba(23, 0, 0, 1) 0%, rgba(0, 0, 0, 0.058440563725490224) 50%, rgba(0, 0, 0, 0) 100%), url('./assets/main.png') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(180deg, rgba(6, 6, 6, 0.9413038961003336) 0%, rgba(2, 2, 2, 0.5989057239057238) 32%, rgba(1, 1, 1, 0.242003367003367) 36%, rgba(3, 3, 3, 1) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 15%;
    padding-bottom: 10%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;

  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 50px;
    background-color: black;
    background-attachment: scroll;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
  }

  .about video {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
    display: none;
  }

  .logo2Mobile {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 0.5px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #494949;
  }


  .conT2 {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conTNew {
    display: none;
  }

  .conTMob {
    font-size: 18px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }


  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .aboutDistribution {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 14px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .detailsAndCon div {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-flow: column nowrap;
    width: 95%;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 18px;
  }

  .detailsAndCon2 img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }


  .storyCon {
    font-size: 16px;
    width: 90%;
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon--2 {
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
  }

  .aboutToken p {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 8%;
    padding-bottom: 8%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd solid;
    flex-flow: column nowrap;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    width: fit-content;
  }

  .parrot {
    width: 90%;

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 16px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 18px;
    margin-left: 50px;
    margin-right: 50px;
    width: 50px;
    height: 50px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    width: 80%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 50px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 18px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 7%;
  }

  .box1 img {
    width: 200px;
    height: 200px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 40px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 16px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .icons img {
    width: 35px;
    height: 35px;
    margin-left: 18px;
    margin-right: 18px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 40px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 100%;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -130px;
    margin-top: -33px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 16px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -130px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
    margin-left: -15px;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 40px;
    height: 40px;
    border: 2px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 6px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 8px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 6px;
    background-color: #c5c3cb8a;
    left: 8px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 20px;
    height: 20px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 90%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 20px;
    padding-top: 50px;
    border-radius: 20px;
  }

  .viewTxn {
    font-size: 16px;
    margin-top: 15px;
    color: rgb(0, 59, 0);
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .containerD {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
    height: 50vh;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .containerD p {
    font-size: 16px;
    color: #02ace0;
    margin-top: 15px;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 40px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);
  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
  }

  .title {
    font-size: 25px;
    margin-bottom: 1rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .progress {
    height: 20px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    color: #d1d1d1;
    flex-flow: column nowrap;
  }

  .stat {
    background-color: #0000006b;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 15px;
    padding-top: 25px;
  }

  .stat h2 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 0.8rem;
    border: none;
    width: 100%;
    border-radius: 5px;
  }


  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 5px;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 20px;
    color: #90c9ff;
    background-color: #3f3f3f6c;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 30%;
    margin: auto;
    display: block;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }  

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 22px;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  } 

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;

    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 5px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 15px;
    border-radius: 100px;
    border: none;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 3px solid #073c6e;
    transition: transform .5s;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 190px;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 20px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 18px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .title_text {
    font-size: 55px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 0.5px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: -80px;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .boxWrap2Page {
    background-color: #010408;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .buyImg {
    width: 80px;
    margin-left: 4%;
    margin-top: 20px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 24px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 4%;
    text-shadow: 0 0 10px #ffffff8a;
  }


  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  .partnerDiv img {
    width: 120px;
    height: 44px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
    margin-bottom: 50px;
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 18px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .tokenLink {
    font-size: 13.5px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    display: none;
  }

  .square {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }


  .glitchLoad {
    font-size: 25px;
    color: white;
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .conTNew {
    display: none;
  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .tokenomicsCon {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #0084ff;
    padding: 20px;
    width: 100%;
    color: black;
    box-shadow: 0 5px #047baa;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;
    flex-flow: column nowrap;

  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 100px;
  }

  .videoMain {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 90%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 5%;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 4%;
    margin-left: 29.3%;
    cursor: pointer;
  }

  video {
    z-index: 1;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 17px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 14px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMain {
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .cContentMainMy {
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .relativeMain {
    width: 100%;
    height: 100%;
    margin-top: 8%;
    display: none;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    position: -webkit-sticky;

  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;


  }

  .contMint {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }


  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 50%;
    margin-top: -28%;
  }

  .rocketsImg2 {
    width: 50%;
    margin-top: -28%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    color: white;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .logoDiv img {
    width: 35%;
  }

  .logoDivMob {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
  }

  .logoDivMob img {
    width: 45%;
    margin-left: 25px;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 18px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 38px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 18px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: cover;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: cover;
    display: none;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 22px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(180deg, rgba(23, 0, 0, 1) 0%, rgba(0, 0, 0, 0.058440563725490224) 50%, rgba(0, 0, 0, 0) 100%), url('./assets/main.png') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 7%;
    margin-bottom: 10%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(180deg, rgba(6, 6, 6, 0.9413038961003336) 0%, rgba(2, 2, 2, 0.5989057239057238) 32%, rgba(1, 1, 1, 0.242003367003367) 36%, rgba(3, 3, 3, 1) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 10%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;

  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 7%;
    background-color: black;
    background-attachment: scroll;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .about video {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
    display: none;
  }

  .logo2Mobile {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 1px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #494949;
  }

  .conT2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 25px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .aboutDistribution {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 14px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .detailsAndCon div {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-flow: column nowrap;
    width: 400px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 18px;
  }

  .detailsAndCon2 img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .storyCon {
    font-size: 16px;
    width: 90%;
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon--2 {
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
  }

  .aboutToken p {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 8%;
    padding-bottom: 8%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd solid;
    flex-flow: column nowrap;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 350px;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }


  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;
    width: 60px;
    height: 60px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 60px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 7%;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 40px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 16px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .icons img {
    width: 35px;
    height: 35px;
    margin-left: 18px;
    margin-right: 18px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 50px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 100%;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -95px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 18px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -95px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 50px;
    height: 50px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 32px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 32px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 25px;
    height: 25px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  #leftT {
    text-align: left;
  }

  #rightT {
    text-align: right;
  }

  .viewTxn {
    font-size: 18px;
    margin-top: 15px;
    color: rgb(0, 59, 0);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 70%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 20px;
    padding-top: 50px;
    border-radius: 20px;
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .containerD {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
    height: 50vh;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .containerD p {
    font-size: 16px;
    color: #02ace0;
    margin-top: 15px;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);
  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
  }

  .title {
    font-size: 30px;
    margin-bottom: 1rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .progress {
    height: 20px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    color: #d1d1d1;
  }

  .stat h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    width: 100%;
  }

  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 5px;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 20px;
    color: #90c9ff;
    background-color: #3f3f3f6c;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 30%;
    margin: auto;
    display: block;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }  

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 22px;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  } 

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 7px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 20px;
    border-radius: 100px;
    border: none;
    width: 90%;
    margin-top: 40px;
    border: 3px solid #073c6e;
    transition: transform .5s;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 140px;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 20px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 18px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }


  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .title_text {
    font-size: 80px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 0.5px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: -80px;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .boxWrap2Page {
    background-color: #010408;

    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .buyImg {
    width: 80px;
    margin-left: 4%;
    margin-top: 20px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 24px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
    display: flex;
  }

  .partnerDiv img {
    width: 150px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
  }

  .logoDivMob img {
    width: 25%;
    margin-left: 25px;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .glitchLoad {
    font-size: 25px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .conTNew {
    display: none;
  }

  .conTMob {
    font-size: 30px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #02ace0;
    padding: 20px;
    width: 100%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }


  .tokenLink {

    font-weight: 500;
    font-size: 22px;
  }

  .tokenLink2 {
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;
    flex-flow: column nowrap;

  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 100px;
  }

  .videoMain {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 80%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;

  }

  .spaceshipMobile {
    display: none;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .headerPic {
    width: 17%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 3%;
  }

  .headerPic1 {
    width: 12%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 24%;
    cursor: pointer;
  }

  .headerPic2 {
    width: 14%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 27%;
    cursor: pointer;

  }

  .headerPic3 {
    width: 26%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 30%;
    cursor: pointer;

  }

  .headerPic4 {
    width: 14.5%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 33%;
    cursor: pointer;

  }

  .headerPic5 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 36%;
    cursor: pointer;

  }

  .headerPic6 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 39%;
    cursor: pointer;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 8%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }


  .num2 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 21.5%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  video {
    z-index: 1;
    width: 80%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 17px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 14px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 60%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }


  .cContentMain1 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 60%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 60%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }



  .num3 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16%;
    margin-left: 15%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain2 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 60%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMain4 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 60%;
    margin-left: 60%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num4 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16.5%;
    cursor: pointer;
    margin-left: 72.5%;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain5 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 60%;
    margin-left: 22%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num5 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 25%;
    margin-left: 28.8%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain6 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 60%;
    margin-left: 47%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 3%;
  }

  .num6 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 26%;
    margin-left: 60%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .relativeMain {
    width: 100%;
    height: 100%;
    margin-top: 8%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;


  }

  .contMint {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 45%;
    margin-top: -30%;
  }

  .rocketsImg2 {
    width: 45%;
    margin-top: -30%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    color: white;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
  }


  .logoDiv img {
    width: 35%;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 55%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: contain;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: contain;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: contain;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: contain;
  }


  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 22px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.12286633403361347) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/main.png') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 7%;
    margin-bottom: 10%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(180deg, rgba(6, 6, 6, 0.9413038961003336) 0%, rgba(2, 2, 2, 0.5989057239057238) 32%, rgba(1, 1, 1, 0.242003367003367) 36%, rgba(3, 3, 3, 1) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 5%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 7%;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 50px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;

  }

  .about video {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 1px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #494949;
  }


  .conT2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }


  .conTMob {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }


  .aboutDistribution {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 20px;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 18px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .detailsAndCon div {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 80px;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-flow: column nowrap;
    width: 400px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 18px;
  }

  .detailsAndCon2 img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .storyCon {
    font-size: 18px;
    width: 90%;
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon--2 {
    font-size: 18px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
  }


  .aboutToken p {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd solid;
    padding: 50px;
    flex-flow: column nowrap;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 350px;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }


  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;
    width: 60px;
    height: 60px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 90%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 250px;
    height: 250px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 40px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 16px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: 3%;

  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 70px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 22px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  #leftT {
    text-align: left;
  }

  #rightT {
    text-align: right;
  }

  .viewTxn {
    font-size: 18px;
    margin-top: 15px;
    color: rgb(0, 59, 0);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 50%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 20px;
    padding-top: 50px;
    border-radius: 20px;
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .containerD {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    height: 50vh;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .containerD p {
    font-size: 16px;
    color: #02ace0;
    margin-top: 1%;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);

  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 70%;
    width: 100%;
  }

  .title {
    font-size: 30px;
    margin-bottom: 1rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .progress {
    height: 20px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    color: #d1d1d1;
  }

  .stat h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    width: 100%;
  }


  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 5px;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 20px;
    color: #90c9ff;
    background-color: #3f3f3f6c;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 30%;
    margin: auto;
    display: block;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }  

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 22px;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  } 

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 5px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 20px;
    border-radius: 100px;
    border: none;
    width: 100%;
    margin-top: 3%;
    border: 3px solid #073c6e;
    transition: transform .5s;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 20px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 20px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .title_text {
    font-size: 80px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 0.5px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
    margin-top: -20px;
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .boxWrap2Page {
    background-color: #010408;

    overflow: hidden;
  }

  .buyImg {
    width: 80px;
    margin-left: 4%;
    margin-top: 20px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
    display: flex;
  }

  .partnerDiv img {
    width: 190px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }


  .logoDivMob {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
  }

  .logoDivMob img {
    width: 25%;
    margin-left: 25px;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }


  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .tokenomicsCon {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #02ace0;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }

  .tokenLink {

    font-size: 16px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;
    flex-flow: column nowrap;
  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 70%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;

  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 100px;
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .spaceshipMobile {
    display: none;
  }


  .headerPic {
    width: 17%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 3%;
  }

  .headerPic1 {
    width: 12%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 24%;
    cursor: pointer;
  }

  .headerPic2 {
    width: 14%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 27%;
    cursor: pointer;

  }

  .headerPic3 {
    width: 26%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 30%;
    cursor: pointer;

  }

  .headerPic4 {
    width: 14.5%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 33%;
    cursor: pointer;

  }

  .headerPic5 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 36%;
    cursor: pointer;

  }

  .headerPic6 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 39%;
    cursor: pointer;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 8%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }


  .num2 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 21.5%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  video {
    z-index: 1;
    width: 80%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 17px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 14px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }


  .cContentMain1 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }



  .num3 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16%;
    margin-left: 15%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain2 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMain4 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 60%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num4 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16.5%;
    cursor: pointer;
    margin-left: 72.5%;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain5 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 22%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num5 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 25%;
    margin-left: 28.8%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain6 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 47%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 3%;
  }

  .num6 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 26%;
    margin-left: 60%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .relativeMain {
    width: 100%;
    height: 100%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0a4796 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 50px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    margin-left: auto;
    margin-right: auto;
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    margin-left: auto;
    width: 33.33vw;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.927608543417367) 50%, rgba(0, 0, 0, 0.6138830532212884) 100%), url('./assets/0_0.jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;


  }

  .introduction {
    margin-bottom: 15vh;

  }

  .contMint {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 40%;
    margin-top: -30%;
  }

  .rocketsImg2 {
    width: 40%;
    margin-top: -30%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    color: white;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 33.33vw;
  }

  .logoDiv img {
    width: 30%;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: contain;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: contain;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: contain;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.12286633403361347) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/main.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 5%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 50px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;

  }

  .about video {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;

  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 50px;
    margin-bottom: 40px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #3f3f3f;
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #494949;
  }


  .conT2 {
    font-size: 50px;
    margin-bottom: 40px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 40px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 40px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }


  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .aboutDistribution {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 50px;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 18px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .detailsAndCon div {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 80px;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-flow: column nowrap;
    width: 400px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 18px;
  }

  .detailsAndCon2 img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 {
    font-size: 18px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .aboutToken p {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd solid;
    padding: 30px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 380px;
    height: 380px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    margin-top: 2%;

  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 80px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}


@media screen and (min-width: 1200px) and (max-width: 1919px) {

  #leftT {
    text-align: left;
  }

  #rightT {
    text-align: right;
  }

  .viewTxn {
    font-size: 18px;
    margin-top: 20px;
    color: rgb(0, 59, 0);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 50%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 20px;
    padding-top: 50px;
    border-radius: 20px;
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .containerD {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    height: 50vh;
  }

  .containerD p {
    font-size: 16px;
    color: #02ace0;
    margin-top: 1%;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);

  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
    margin-top: 3%;
  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 70%;
    width: 100%;
  }

  .title {
    font-size: 30px;
    margin-bottom: 1rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .progress {
    height: 20px;
    background-color: #4caf50;
  }

  .pText {
    text-align: center;
    margin: auto;
    display: block;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    color: #d1d1d1;
  }

  .stat h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    width: 100%;
  }

  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 5px;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 20px;
    color: #90c9ff;
    background-color: #3f3f3f6c;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 30%;
    margin: auto;
    display: block;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 22px;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  } 

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 5px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 20px;
    border-radius: 100px;
    border: none;
    width: 70%;
    margin-top: 3%;
    border: 3px solid #073c6e;
    transition: transform .5s;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 20px;
  }


  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 20px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .title_text {
    font-size: 80px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 0.5px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .explore {
    font-family: "JetBrains Mono", monospace;
    color: white;
    font-size: 18px;
    background: rgb(0, 132, 255);
    background: linear-gradient(180deg, rgba(0, 132, 255, 1) 0%, rgba(0, 127, 245, 1) 50%, rgba(117, 182, 242, 1) 100%);
    width: 200px;
    border-radius: 100px;
    border: none;
    padding: 10px;
    margin-top: 2%;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    z-index: 1000;
  }

  .buyImg {
    width: 85px;
    margin-left: 3%;
    margin-top: 10px;
  }

  .boxWrap2Page {
    background-color: #010408;
    overflow: hidden;
  }

  .usdAmount img {
    width: 30px;
    height: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 81px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }


  .usdAmount {
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 10px;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 22px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }


  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .glitchLoad {
    font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }


  .tokenomicsCon {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #02ace0;
    padding: 20px;
    width: 90%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }

  .tokenLink {

    font-size: 20px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 200px;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 50px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 10px;
    margin-top: 3%;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .spaceshipMobile {
    display: none;
  }

  .headerPic {
    width: 17%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 3%;
  }

  .headerPic1 {
    width: 12%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 24%;
    cursor: pointer;
  }

  .headerPic2 {
    width: 14%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 27%;
    cursor: pointer;

  }

  .headerPic3 {
    width: 26%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 30%;
    cursor: pointer;

  }

  .headerPic4 {
    width: 14.5%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 33%;
    cursor: pointer;

  }

  .headerPic5 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 36%;
    cursor: pointer;

  }

  .headerPic6 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 39%;
    cursor: pointer;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 8%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }


  .num2 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 21.5%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  video {
    z-index: 1;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 17px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 14px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }


  .cContentMain1 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }



  .num3 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16%;
    margin-left: 15%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain2 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMain4 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 60%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num4 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16.5%;
    cursor: pointer;
    margin-left: 72.5%;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain5 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 22%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num5 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 25%;
    margin-left: 28.8%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain6 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 47%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 3%;
  }

  .num6 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 26%;
    margin-left: 60%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .relativeMain {
    width: 100%;
    height: 100%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-top: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 3px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.927608543417367) 50%, rgba(0, 0, 0, 0.6138830532212884) 100%), url('./assets/0_0.jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .contMint {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    margin-top: -28%;
  }

  .rocketsImg2 {
    width: 30%;
    margin-top: -28%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 25px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: contain;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: contain;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: contain;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.12286633403361347) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/main.png') no-repeat;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;

  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 5%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .aboutDistribution {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 50px;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }


  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 50px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 1px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 1px #494949;
  }

  .conT2 {
    font-size: 60px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conTNew {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;

  }

  .conTMob {
    font-size: 25px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyConToken {
    font-size: 18px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .detailsAndCon div {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 80px;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 25px;
    background-color: #00000077;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-flow: column nowrap;
    width: 400px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
  }

  .detailsAndCon2 img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }


  .storyConToken p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }


  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon--2 {
    font-size: 18px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .aboutToken p {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px #81f5fd solid;
    padding: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 400px;
    height: 400px;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 40px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }


  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 90%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 90px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 80px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .square {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1920px) {

  #leftT {
    text-align: left;
  }

  #rightT {
    text-align: right;
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .pText {
    text-align: center;
    margin: auto;
    display: block;
    font-size: 16px;
  }

  .viewTxn {
    font-size: 24px;
    margin-top: 25px;
    color: rgb(0, 59, 0);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 50%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 20px;
    padding-top: 60px;
    border-radius: 30px;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;

    background-color: rgba(172, 67, 67, 0);
    width: 150px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 12px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -12px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .containerD {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    height: 50vh;
  }

  .containerD p {
    font-size: 22px;
    color: #02ace0;
    margin-top: 1%;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 70px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);

  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
    margin-top: 3%;
  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 70%;
    width: 100%;
  }

  .title {
    font-size: 40px;
    margin-bottom: 2rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 1.7rem;
    margin-bottom: 3rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 7px;
    overflow: hidden;
    margin-bottom: 1.2rem;
  }

  .progress {
    height: 25px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    color: #d1d1d1;
    font-size: 24px;
  }

  .stat h2 {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 1.2rem;
    border: none;
    border-radius: 10px;
    width: 100%;
  }

  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 15px;
    padding-right: 25px;
    margin-bottom: 15px;
  }

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 32px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  }
  
.amount {
  font-size: 30px;
  color: #90c9ff;
  background-color: #3f3f3f6c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 30%;
  margin: auto;
  display: block;
  padding: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
    font-size: 1.4rem;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1.4rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 7px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 25px;
    border-radius: 100px;
    border: none;
    width: 70%;
    margin-top: 3%;
    border: 4px solid #073c6e;
    transition: transform .5s;
    font-size: 30px;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18%;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px;
    border: 5px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 40px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 30px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .title_text {
    font-size: 110px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 1px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 22px;
    margin-top: 30px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }



  .buyImg {
    width: 140px;
    margin-left: 3%;
    margin-top: 20px;
  }

  .boxWrap2Page {
    background-color: #010408;

    overflow: hidden;
  }

  .usdAmount img {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 60px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }


  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 320px;
    height: 118px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  .usdAmount {
    color: white;
    font-size: 25px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 2px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 20px;
    border: 3px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 50px;
    margin-bottom: 80px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 33px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .square {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .glitchLoad {
    font-size: 45px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .tokenomicsCon {
    font-size: 35px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #02ace0;
    padding: 30px;
    width: 90%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }

  .tokenLink {

    font-size: 30px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 30px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 200px;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 80px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 20px;
    padding: 20px;
    margin-top: 3%;

    font-size: 24px;
  }

  .spaceshipMobile {
    display: none;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .headerPic {
    width: 17%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 3%;
  }

  .headerPic1 {
    width: 12%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 24%;
    cursor: pointer;
  }

  .headerPic2 {
    width: 14%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 27%;
    cursor: pointer;

  }

  .headerPic3 {
    width: 26%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 30%;
    cursor: pointer;

  }

  .headerPic4 {
    width: 14.5%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 33%;
    cursor: pointer;

  }

  .headerPic5 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 36%;
    cursor: pointer;

  }

  .headerPic6 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 39%;
    cursor: pointer;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 8%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }


  .num2 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 21.5%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  video {
    z-index: 1;
    width: 80%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 22px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 20px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }


  .cContentMain1 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }



  .num3 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16%;
    margin-left: 15%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain2 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMain4 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 60%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num4 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16.5%;
    cursor: pointer;
    margin-left: 72.5%;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain5 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 22%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num5 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 25%;
    margin-left: 28.8%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain6 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 47%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 3%;
  }

  .num6 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 26%;
    margin-left: 60%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .relativeMain {
    width: 100%;
    height: 100%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 26px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 12px;
    font-size: 24px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 45px;
    height: 45px;
    margin-left: 12px;
    margin-right: 12px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.927608543417367) 50%, rgba(0, 0, 0, 0.6138830532212884) 100%), url('./assets/0_0.jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;


  }

  .contMint {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 35%;
    margin-top: -25%;
  }

  .rocketsImg2 {
    width: 35%;
    margin-top: -25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
  }

  .logoDiv img {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 38px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(45, 35, 66, .4) 0 4px 6px, rgba(45, 35, 66, .3) 0 9px 15px -5px, rgba(58, 65, 111, .5) 0 -5px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 70px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 28px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 60px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: contain;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: contain;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: contain;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: contain;
  }


  .ticker-wrap .ticker {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2.5rem;
    font-size: 33px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.12286633403361347) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/main.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 5%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 70px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;

  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 80px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 2px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 2px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 2px #494949;
  }

  .conT2 {
    font-size: 80px;
    margin-bottom: 80px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conTNew {
    font-size: 30px;
    margin-bottom: 80px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;

  }

  .conTMob {
    font-size: 30px;
    margin-bottom: 80px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .aboutDistribution {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 50px;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 25px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 200px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 35px;
    background-color: #00000077;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .detailsAndCon div {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 120px;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
    background-color: #00000077;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-flow: column nowrap;
    width: 550px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 25px;
  }

  .detailsAndCon2 img {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .storyCon {
    font-size: 25px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 {
    font-size: 25px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .aboutToken p {
    color: white;
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 10px #81f5fd solid;
    padding: 80px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 580px;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 28px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    margin-top: 50px;
    margin-bottom: 50px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 70px;
    margin-right: 70px;
    width: 90px;
    height: 90px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 30px;
    width: 90%;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 30px;
    width: 90%;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 130px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 23px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 28px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 28px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 400px;
    height: 400px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 50px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 15px -15px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 70px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 26px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 80px;
    color: #aaaaaa98;
    font-size: 28px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .icons img {
    width: 75px;
    height: 75px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 25px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 110px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 900px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 65px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -20px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 35px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 2;
    color: #fff;
    font-size: 26px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -20px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;
    width: max-content;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: -10px;
    top: 0;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 90px;
    height: 90px;
    border: 6px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 12px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 12px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
    width: 120px;
    height: 120px;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;

  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 28px;
    color: rgb(0, 0, 0);
    padding: 3rem;
    margin-bottom: 4rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 40px;
    text-align: left;
    font-size: 28px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  #leftT {
    text-align: left;
  }

  #rightT {
    text-align: right;
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .pText {
    text-align: center;
    margin: auto;
    display: block;
    font-size: 22px;
  }

  .viewTxn {
    font-size: 32px;
    margin-top: 35px;
    color: rgb(0, 59, 0);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 50%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 40px;
    padding-top: 80px;
    border-radius: 40px;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 60px;

    background-color: rgba(172, 67, 67, 0);
    width: 200px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 12px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -12px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .containerD {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    height: 50vh;
  }

  .containerD p {
    font-size: 32px;
    color: #02ace0;
    margin-top: 1%;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 100px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);
  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
    margin-top: 3%;

  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 70%;
    width: 100%;
  }

  .title {
    font-size: 55px;
    margin-bottom: 2rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 2rem;
    margin-bottom: 4rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }

  .progress {
    height: 35px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    color: #d1d1d1;
    font-size: 34px;
  }

  .stat h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 1.4rem;
    border: none;
    border-radius: 14px;
    width: 100%;
  }

  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 15px;
    padding-right: 25px;
    margin-bottom: 15px;
  }

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 45px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  }
  
.amount {
  font-size: 40px;
  color: #90c9ff;
  background-color: #3f3f3f6c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 30%;
  margin: auto;
  display: block;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
    font-size: 1.9rem;
  }

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 8px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 35px;
    border-radius: 100px;
    border: none;
    width: 70%;
    margin-top: 3%;
    border: 6px solid #073c6e;
    transition: transform .5s;
    font-size: 40px;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 60px;
    border: 9px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 50px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 40px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .title_text {
    font-size: 150px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 1px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 30px;
    margin-top: 45px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .boxWrap2Page {
    background-color: #010408;

    overflow: hidden;
  }

  .buyImg {
    width: 150px;
    margin-left: 3%;
    margin-top: 20px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 90px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .usdAmount img {
    width: 60px;
    height: 60px;
    margin-top: -5px;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 430px;
    height: 158px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  .usdAmount {
    color: white;
    font-size: 35px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 4px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 30px;
    border: 3px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 80px;
    margin-bottom: 100px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 38px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }


  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .square {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .glitchLoad {
    font-size: 55px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .tokenomicsCon {
    font-size: 50px;
    margin-top: 70px;
    margin-bottom: 70px;
    background-color: #02ace0;
    padding: 40px;
    width: 90%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }

  .tokenLink {

    font-size: 40px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 40px;
    font-weight: 500;
    cursor: pointer;
  }

  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 200px;
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 100px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 40px;
    padding: 30px;
    font-size: 30px;
    margin-top: 3%;

  }

  .spaceshipMobile {
    display: none;
  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .headerPic {
    width: 17%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 3%;
  }

  .headerPic1 {
    width: 12%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 24%;
    cursor: pointer;
  }

  .headerPic2 {
    width: 14%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 27%;
    cursor: pointer;

  }

  .headerPic3 {
    width: 26%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 30%;
    cursor: pointer;

  }

  .headerPic4 {
    width: 14.5%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 33%;
    cursor: pointer;

  }

  .headerPic5 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 36%;
    cursor: pointer;

  }

  .headerPic6 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 39%;
    cursor: pointer;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 8%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }


  .num2 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 21.5%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  video {
    z-index: 1;
    width: 80%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 28px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 25px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }


  .cContentMain1 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }



  .num3 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16%;
    margin-left: 15%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain2 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMain4 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 60%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num4 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16.5%;
    cursor: pointer;
    margin-left: 72.5%;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain5 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 22%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num5 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 25%;
    margin-left: 28.8%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain6 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 47%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 3%;
  }

  .num6 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 26%;
    margin-left: 60%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .relativeMain {
    width: 100%;
    height: 100%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 33px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 40px;
    margin-right: 40px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 32px;
    padding-left: 40px;
    padding-right: 40px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }


  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 60px;
    height: 60px;
    margin-left: 18px;
    margin-right: 18px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.927608543417367) 50%, rgba(0, 0, 0, 0.6138830532212884) 100%), url('./assets/0_0.jpeg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;


  }

  .contMint {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 35%;
    margin-top: -25%;
  }

  .rocketsImg2 {
    width: 35%;
    margin-top: -25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    color: white;
  }

  .logoDiv img {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 48px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 60px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(45, 35, 66, .4) 0 6px 8px, rgba(45, 35, 66, .3) 0 11px 17px -7px, rgba(58, 65, 111, .5) 0 -6px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 90px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 37px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 80px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 11rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: contain;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: contain;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: contain;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: contain;
  }


  .ticker-wrap .ticker {
    display: inline-block;
    height: 11rem;
    line-height: 11rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3.5rem;
    font-size: 46px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.12286633403361347) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/main.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 5%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 80px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;

  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 115px;
    margin-bottom: 30px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 2.5px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 2.5px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 2.5px #494949;
  }


  .conT2 {
    font-size: 115px;
    margin-bottom: 120px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conTNew {
    font-size: 45px;
    margin-bottom: 120px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;

  }

  .conTMob {
    font-size: 45px;
    margin-bottom: 120px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .aboutDistribution {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 50px;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 40px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 36px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 200px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 55px;
    background-color: #00000077;
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .detailsAndCon div {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 150px;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px;
    background-color: #00000077;
    margin-top: 60px;
    margin-bottom: 60px;
    flex-flow: column nowrap;
    width: 750px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 36px;
  }

  .detailsAndCon2 img {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }


  .storyCon {
    font-size: 36px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 {
    font-size: 36px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }


  .aboutToken p {
    color: white;
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 10px #81f5fd solid;
    padding: 100px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 700px;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 38px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    margin-top: 50px;
    margin-bottom: 80px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 70px;
    margin-left: 90px;
    margin-right: 90px;
    width: 130px;
    height: 130px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 5px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 45px;
    width: 90%;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 45px;
    width: 90%;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 170px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 37px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 37px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 550px;
    height: 550px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 60px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 20px -20px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 90px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 35px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 100px;
    color: #aaaaaa98;
    font-size: 42px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .icons img {
    width: 95px;
    height: 95px;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 35px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 140px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 900px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 90px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -20px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 45px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 3;
    color: #fff;
    font-size: 33px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -20px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;
    width: max-content;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: -10px;
    top: 0;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 90px;
    height: 90px;
    border: 6px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 12px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 12px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
    width: 120px;
    height: 120px;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;

  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 55px;
    height: 55px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 38px;
    color: rgb(0, 0, 0);
    padding: 4rem;
    margin-bottom: 5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 60px;
    text-align: left;
    font-size: 38px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 3840px) {

  .purchase-form input {
    background-color: #333;
    color: #ffffff;
    font-size: 3rem;
  }
  #leftT {
    text-align: left;
  }

  #rightT {
    text-align: right;
  }

  .check {
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .pText {
    text-align: center;
    margin: auto;
    display: block;
    font-size: 30px;
  }

  .viewTxn {
    font-size: 42px;
    margin-top: 45px;
    color: rgb(0, 59, 0);
  }

  .formMain-2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 255, 255);
    width: 50%;
    animation: popupAnimation 0.5s ease forwards;
    padding: 70px;
    padding-top: 90px;
    border-radius: 40px;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 16px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: -16px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 90px;

    background-color: rgba(172, 67, 67, 0);
    width: 300px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .containerD {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 5%;
    height: 50vh;
  }

  .containerD p {
    font-size: 45px;
    color: #02ace0;
    margin-top: 1%;
  }

  .dev {
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
    font-size: 140px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.158), 0 -10px 20px #ffd0006c, 0 -18px 40px rgba(255, 208, 0, 0.5);

  }

  .presale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
    margin-top: 3%;

  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    max-width: 70%;
    width: 100%;
  }

  .title {
    font-size: 85px;
    margin-bottom: 3rem;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    color: white;
  }

  .description {
    font-size: 3rem;
    margin-bottom: 5rem;
    color: rgb(143, 143, 143);
  }

  .progress-bar {
    width: 100%;
    background-color: #535353;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 1.8rem;
  }

  .progress {
    height: 55px;
    background-color: #4caf50;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    color: #d1d1d1;
    font-size: 45px;
  }

  .stat h2 {
    font-size: 4.2rem;
    margin-bottom: 1rem;
    color: #02ace0;
  }

  .purchase-form {
    display: flex;
    flex-direction: column;
  }

  .purchase-form input,
  .purchase-form button {
    padding: 2rem;
    border: none;
    border-radius: 18px;
    width: 100%;
  }


  .inputSection {
    display: flex;
    background-color: #333;
    justify-content: space-between;
    border-radius: 15px;
    padding-right: 35px;
    margin-bottom: 25px;
  }

  .Receive {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: 60px;
    background-color: rgba(104, 104, 104, 0);
    border: none;
  }
  
.amount {
  font-size: 52px;
  color: #90c9ff;
  background-color: #3f3f3f6c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 30%;
  margin: auto;
  display: block;
  padding:30px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

  .purchase-form button {
    background-color: #4caf50;
    color: #ffffff;
    font-size: 3rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .purchase-form button:hover {
    background-color: #45a049;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .btnPresale {
    background-color: #0084ff;
    color: #022b52;
    box-shadow: 0 0 20px #0b5497;
    animation: glow 3s infinite, textFade 3s infinite;
    rotate: -3deg;
    font-family: 'Rhodeport', sans-serif;
    letter-spacing: 10px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    padding: 45px;
    border-radius: 100px;
    border: none;
    width: 70%;
    margin-top: 3%;
    border: 8px solid #073c6e;
    transition: transform .5s;
    font-size: 55px;
  }

  .btnPresale:hover {
    transform: scale(1.2);
  }

  .presaleSection {
    color: white;
    z-index: 5;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 75px;
    border: 12px solid transparent;
    border-image: linear-gradient(90deg, #797979, #d8d8d8);
    border-image-slice: 3;
    box-shadow: 0 0 20px rgba(38, 74, 153, 0.7);
    cursor: pointer;
  }

  .pulse {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgb(172, 248, 72);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(139, 206, 51, 0.507);
    animation: pulse 1.5s infinite;
    margin-left: 100px;
  }

  .glitch-wrapper3 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch3 {
    font-size: 55px;
    color: rgb(172, 248, 72);
    text-align: left;
    position: relative;
    z-index: 1;
    display: flex;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;
  }

  .glitch3::before,
  .glitch3::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch3::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch3::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  .introMain {
    position: relative;
    width: 100%;
    height: 100vh;
    /* 100% of the viewport height */
    overflow: hidden;
  }

  .title_text {
    font-size: 220px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title_text_tagline {

    font-family: 'Rhodeport', sans-serif;
    color: #a7a7a7;
    -webkit-text-stroke: black 2px;
    font-size: smaller;
    text-transform: uppercase;
    font-size: 45px;
    margin-top: 75px;
    letter-spacing: 16px;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: -3px -3px 3px rgba(255, 255, 255, .1), 3px 3px 3px rgba(0, 0, 0, .5);
  }

  #title {
    margin-top: 10%;

  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(6, 6, 6, 0.022846638655462215) 0%, rgba(0, 0, 0, 0.053658963585434205) 21%, rgba(3, 3, 3, 1) 100%);
    z-index: 1;
    /* Place it over the video */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will ensure the video covers the container */
    z-index: 0;
    /* Place it behind the gradient */
  }


  .introMainDiv1 {
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    font-family: 'Rhodeport', sans-serif;
    text-align: center;
    z-index: 1000;
    position: relative;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl3 {
    background: -webkit-linear-gradient(#ffffff, #fcc93c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rhodeport', sans-serif;

  }

  .introMainDiv2 {
    font-size: 15px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Rhodeport', sans-serif;
    margin-top: 15px;
    letter-spacing: 7px;
    text-align: center;
    z-index: 1000;
    position: relative;
  }

  .txtMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .boxWrap2Page {
    background-color: #010408;

    overflow: hidden;
  }

  .buyImg {
    width: 210px;
    margin-left: 3%;
    margin-top: 30px;
  }

  .conT4 {
    color: white;
    text-align: center;
    font-size: 120px;
    font-family: 'Rhodeport', sans-serif;
    margin-bottom: 2%;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 650px;
    height: 239px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    opacity: 0.85;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  .usdAmount img {
    width: 90px;
    height: 90px;
    margin-top: -5px;
  }

  .usdAmount {
    color: white;
    font-size: 50px;
    text-shadow: 0 0 10px #FFFFFF;
    border: 4px solid #62b9e7;
    box-shadow: 0 0 10px #e82598, 0 0 20px #e82598, 0 0 30px #e82598;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1d2129;
    padding: 50px;
    border: 6px solid #62b9e7;
    box-shadow: 0 0 5px #e82598, 0 0 15px #e82598, 0 0 25px #e82598;
    animation: neonGlow 2s infinite;
    /* Adjust the duration as needed */
    margin-top: 80px;
    margin-bottom: 120px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: linear-gradient(309deg, rgba(33, 43, 54, 1) 0%, rgba(20, 25, 31, 1) 50%, rgba(59, 42, 69, 1) 100%);
  }

  .usdT {
    font-family: 'Rhodeport', sans-serif;
    font-size: 60px;
  }

  .usd2 {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 90%;
  }

  .logo2Mobile {
    display: none;
  }

  .logoDivMob {
    display: none;
  }

  .token {
    width: 40%;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }


  .glitchLoad {
    font-size: 70px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .tokenomicsCon {
    font-size: 70px;
    margin-top: 90px;
    margin-bottom: 90px;
    background-color: #02ace0;
    padding: 60px;
    width: 90%;
    color: black;
    box-shadow: 0 5px #047baa;
    ;
  }

  .tokenLink {

    font-size: 60px;
    font-weight: 500;
  }

  .tokenLink2 {
    font-size: 60px;
    font-weight: 500;
    cursor: pointer;
  }


  .about3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: fit-content;
    margin-top: 7%;

  }

  .videoMain {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .aboutA {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    margin-left: auto;
    width: 95%;
    padding: 50px;

  }

  .aboutToken {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    background-color: #00000077;
    padding: 50px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 200px;
  }

  .punkSubH {
    color: rgb(192, 251, 255);
    margin-bottom: 150px;
    width: 50%;
    margin-left: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #0000003a;
    border-radius: 60px;
    padding: 40px;
    font-size: 47px;
    margin-top: 3%;

  }

  .stickyRm {
    position: sticky;
    bottom: 0;
    margin-top: -10%;
    width: 40%;

    margin-left: 60%;

  }

  .spaceshipMobile {
    display: none;
  }

  .headerPic {
    width: 17%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 3%;
  }

  .headerPic1 {
    width: 12%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 24%;
    cursor: pointer;
  }

  .headerPic2 {
    width: 14%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 27%;
    cursor: pointer;

  }

  .headerPic3 {
    width: 26%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 30%;
    cursor: pointer;

  }

  .headerPic4 {
    width: 14.5%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 33%;
    cursor: pointer;

  }

  .headerPic5 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 36%;
    cursor: pointer;

  }

  .headerPic6 {
    width: 19%;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    margin-top: 39%;
    cursor: pointer;

  }

  .num1 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 8%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }


  .num2 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 21.5%;
    margin-left: 45.7%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  video {
    z-index: 1;
    width: 80%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  /*
  .cContentMain{
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
    width: fit-content;
  }*/

  .cH {
    font-size: 50px;
    z-index: 1;
    color: #0bffff;

  }

  .cC {
    font-size: 40px;
    z-index: 1;
    font-family: "JetBrains Mono", monospace;
    color: white;


  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }


  .cContentMain1 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMainMy {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(171, 245, 255, 0.582);
    box-shadow: 0 0 10px white;
    width: fit-content;
    width: 35%;
    margin-left: 20%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }



  .num3 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16%;
    margin-left: 15%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain2 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 33%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .cContentMain4 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 60%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num4 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 16.5%;
    cursor: pointer;
    margin-left: 72.5%;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain5 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 22%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 1%;
  }

  .num5 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 25%;
    margin-left: 28.8%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .cContentMain6 {
    cursor: pointer;

    position: absolute;

    display: block;
    opacity: 0;
    animation: popup 0.3s ease forwards;
    border: 1px solid rgba(47, 231, 255, 0.582);
    box-shadow: 0 0 10px rgba(47, 231, 255, 0.733);
    width: fit-content;
    width: 35%;
    margin-left: 47%;
    padding: 15px;
    background-color: #00000083;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-top: 3%;
  }

  .num6 {
    position: absolute;
    width: 8%;
    z-index: 1;
    margin-top: 26%;
    margin-left: 60%;
    cursor: pointer;
    animation: blink 2s infinite;
    /* Apply the animation */

  }

  .relativeMain {
    width: 100%;
    height: 100%;
  }

  .bg {
    background-position: center;
    width: 100%;
    height: min-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;

    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 55px;
    cursor: pointer;

    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 50px;
    margin-right: 50px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 26px;
    font-size: 48px;
    padding-left: 50px;
    padding-right: 50px;
    border-width: 3px;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
    background-color: #3396ff;
    border: none;
    border-radius: 25px;
    font-weight: 500;
    margin-top: 7px;
  }

  .connectBtn:hover {
    color: black;
  }


  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 85px;
    height: 85px;
    margin-left: 24px;
    margin-right: 24px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.927608543417367) 50%, rgba(0, 0, 0, 0.6138830532212884) 100%), url('./assets/0_0.jpeg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;


  }

  .contMint {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
  }


  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 35%;
    margin-top: -25%;
  }

  .rocketsImg2 {
    width: 35%;
    margin-top: -25%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    color: white;
  }

  .logoDiv img {
    width: 22%;
    display: flex;
    justify-content: center;
  }

  .title {
    margin-top: 5vh;
    z-index: 1;
    justify-content: center;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: 'Rhodeport', sans-serif;
    font-size: 70px;
    text-shadow: 0 0 10px #02ace0;
    ;
  }

  .button-29 {
    margin-top: 80px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 12px;
    box-shadow: rgba(45, 35, 66, .4) 0 6px 8px, rgba(45, 35, 66, .3) 0 11px 17px -7px, rgba(58, 65, 111, .5) 0 -6px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 130px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 50px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #02ace0;
    ;
  }

  .download {
    width: 120px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }

  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 18rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;
  }

  .ticker-wrap-2 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line2.jpg');
    background-size: contain;
  }

  .ticker-wrap-3 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line3.jpg');
    background-size: contain;
  }

  .ticker-wrap-4 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line4.jpg');
    background-size: contain;
  }

  .ticker-wrap-5 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line5.jpg');
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 18rem;
    line-height: 18rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3.5rem;
    font-size: 65px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 10%;
    padding-bottom: 10%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.12286633403361347) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/main.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2StoryB {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/59243.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Story3 {
    padding-top: 10%;
    padding-bottom: 10%;

    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/34622-NZROKS.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Story2 {
    padding-top: 10%;
    padding-bottom: 10%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11166185224089631) 0%, rgba(19, 0, 0, 0.4113817401960784) 50%, rgba(0, 0, 0, 0.8231464460784313) 100%), url('./assets/0A374238-481E-4015-830C-C68F08302F07 (1).jpeg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.jpg') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 5%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 30vh;
    padding-bottom: 30vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6979166666666667) 0%, rgba(0, 0, 0, 0.7791491596638656) 50%, rgba(0, 0, 0, 0.639093137254902) 100%), url('./assets/footer.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Rm {
    padding-top: 7%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 90px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #00000077;

  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 35%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;

  }

  .about video {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 170px;
    margin-bottom: 40px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .hl2 {
    font-family: 'Rhodeport', sans-serif;
    color: #02ace0;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 4px #3f3f3f;
  }

  .hl2-1 {
    font-family: 'Rhodeport', sans-serif;
    color: #6d6d6d;
    -webkit-text-stroke: 4px rgb(255, 205, 96);
  }

  .hl2-2 {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(255, 205, 96);
    -webkit-text-stroke: 4px #494949;
  }

  .conT2 {
    font-size: 170px;
    margin-bottom: 150px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conTNew {
    font-size: 70px;
    margin-bottom: 150px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;

  }

  .conTMob {
    font-size: 70px;
    margin-bottom: 150px;
    color: white;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: left;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Rhodeport', sans-serif;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Rhodeport', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .aboutDistribution {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 50px;
  }

  .aboutDistribution2 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .storyConToken p {
    color: white;
    margin-bottom: 40px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyConToken {
    font-size: 50px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 200px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 80px;
    background-color: #00000077;
    margin-top: 110px;
    margin-bottom: 110px;
  }

  .detailsAndCon div {
    width: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detailsAndCon img {
    width: 230px;
  }

  .detailsAndCon2 {
    display: flex;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 60px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
    background-color: #00000077;
    margin-top: 120px;
    margin-bottom: 120px;
    flex-flow: column nowrap;
    width: 1120px;
    background: rgb(26, 34, 37);
    background: linear-gradient(270deg, rgba(26, 34, 37, 1) 0%, rgba(0, 26, 36, 1) 50%, rgba(0, 26, 36, 1) 100%);
  }

  .detailsAndCon2 div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 50px;
  }

  .detailsAndCon2 img {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }


  .storyCon {
    font-size: 50px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 {
    font-size: 50px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon--2 p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .top {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .aboutToken p {
    color: white;
    font-size: 50px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 12px #81f5fd solid;
    padding: 120px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 1100px;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 130px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;

    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 55px;
    color: rgb(255, 255, 255);

    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    margin-top: 80px;
    margin-bottom: 120px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 90px;
    margin-left: 90px;
    margin-right: 90px;
    width: 180px;
    height: 180px;
    border: none;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 7px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 70px;
    width: 90%;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 70px;
    width: 90%;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #4d7180, #1c5386);
    border-image-slice: 1;
    color: rgb(107, 107, 107);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
    cursor: no-drop;
  }

  .btnfos-0-3-3:hover {
    background-color: rgb(97, 142, 179);
  }

  .btnfos-0-3-3:active {
    background-color: rgb(97, 142, 179);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 220px;
    font-family: 'Rhodeport', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 50px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .success-message p {
    font-size: 40px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 60px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 60px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 780px;
    height: 780px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 80px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 20px -20px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Rhodeport', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 130px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 55px;
    color: whitesmoke;
  }

  .subTeam {
    text-align: center;
    margin-top: 120px;
    color: #aaaaaa98;
    font-size: 55px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
    margin-top: 2%;

  }

  .icons img {
    width: 120px;
    height: 120px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 55px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 200px;
    color: white;
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Rhodeport', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Rhodeport', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Rhodeport', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 900px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 120px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -10px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 60px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 3;
    color: #fff;
    font-size: 50px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -10px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;
    width: max-content;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: -180px;
    top: 0;
    width: 260px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    margin-top: -50px;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 190px;
    height: 190px;
    border: 10px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 20px;
    background-color: #bf22ba;
    box-shadow: 0 0 15px #ea19dc;
    left: -60px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 20px;
    background-color: #c5c3cb8a;
    left: -60px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ea19dc;
    box-shadow: 0 0 15px #ff5ff6;
    width: 260px;
    height: 260px;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #bf22ba;

  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 120px;
    height: 120px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 82px;
    height: 82px;
    margin-right: 20px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 55px;
    color: rgb(0, 0, 0);
    padding: 5rem;
    margin-bottom: 8rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 8px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 80px;
    text-align: left;
    font-size: 55px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

.usdT img {
  margin-left: 8px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.purchase-form button {
  transition: background-color 0.3s, transform 0.1s;
}

.purchase-form input,
.purchase-form button {
  border: none;
  outline: none;
}